<template>
	<v-app>
		<navbar v-if="!pageLoading && $vuetify.breakpoint.smAndUp" />
		<v-main>
			<terms-and-conditions />
			<page-loading v-if="pageLoading" />
			<router-view v-else />
		</v-main>
		<bottom-nav v-if="!pageLoading && $vuetify.breakpoint.xs" />
	</v-app>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import BottomNav from "@/components/BottomNav";
import PageLoading from "@/components/PageLoading";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

export default {
	name: "AppLayoutAuth",
	components: {
		Navbar,
		PageLoading,
		BottomNav,
		TermsAndConditions,
	},
	computed: {
		...mapState({
			pageLoading: (state) => state.pageLoading,
		}),
	},
};
</script>
